<template>
    <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
            <v-btn dark rounded v-bind="attrs" v-on="on"> Logout </v-btn>
        </template>
        <v-list>
            <v-list-item @click="$auth.logout()">
                <v-list-item-title class="cursor-pointer">
                    Logout
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>
export default {
    name: "auth-user-dropdown",
};
</script>

<style scoped>
</style>
