export default [
    {
        to: { name: 'admin.submitted-nft' },
        icon: 'mdi-playlist-plus',
        text: 'Submitted NFT',
    },
    {
        to: { name: 'admin.all-nft' },
        icon: 'mdi-format-list-bulleted',
        text: 'All NFT',
    },
    {
        to: { name: 'admin.top-nft' },
        icon: 'mdi-star',
        text: 'Top NFT',
    },
    {
        to: { name: 'admin.giveaway' },
        icon: 'mdi-gift',
        text: 'Giveaway',
    },
    {
        to: { name: 'admin.settings' },
        icon: 'mdi-cog',
        text: 'Settings',
    },
    {
        to: { name: 'admin.feedback' },
        icon: 'mdi-message-text',
        text: 'Feedback',
    },
]
