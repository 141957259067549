<template>
    <v-app-bar app color="primary" clipped-left>
        <v-app-bar-nav-icon @click="$emit('toggleDrawer')" v-if="isDashboard" />

        <v-spacer></v-spacer>

        <auth-user-dropdown v-if="$auth.user()" />
        <v-btn text :to="{ name: 'login' }" v-else>Login</v-btn>
    </v-app-bar>
</template>

<script>
import AuthUserDropdown from "./AuthUserDropdown";
export default {
    name: "top-menu",
    props: {
        isDashboard: {
            default: false,
            type: Boolean,
        },
    },
    computed: {
        appName() {
            return process.env.VUE_APP_APP_NAME
                ? process.env.VUE_APP_APP_NAME
                : "Saas";
        },
    },
    components: { AuthUserDropdown },
};
</script>

<style scoped>
</style>
